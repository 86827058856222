import React from "react"
import Helmet from "react-helmet"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import Disclosure from "src/components/disclaimers"
import {isIeEdge, isChrome, isFirefox, isSafari} from "@tightrope/lpscripts/browserdetect"
import "./index.css"

export default function Homepage() {
  let disclosure = null;

  if(isIeEdge()){
      disclosure = <Disclosure language='en-mbc-edge' />;
  }
  if(isFirefox()){
      disclosure = <Disclosure language='en-firefox' />;
  }
  if(isSafari()) {
      disclosure = <Disclosure language='en-safari' />;
  }
  if(isChrome()){
      disclosure = <Disclosure language='en-mbc-chrome' />;
  }

  return (
    <HomepageLayout>
      <Helmet>
        <title>Get Better Search - Enhanced Search Experience</title>
        <link rel="icon" type="image/png" href="/img/favicon.png" />
        <style type="text/css">{`
          #disclaimers-module--disclaimers p {
            text-align: center;
            color: #777;
            font-size: 13px;
            width: 314px;
            line-height: 150%;
            margin: 0 auto 12px;
          }
          #disclaimers-module--disclaimers p a {
            color: #0080e6;
          }
        `}</style>
      </Helmet>

      <div className="homepage">
        <header className="main-header">
          <div className="container">
            <img src={JSONData.headerLogo || "/img/mainlogo.png"} alt="Get Better Search Logo" className="logo" />
          </div>
        </header>

        <main>
          <section className="hero">
            <div className="container">
              <div className="hero-content">
                <h1>Get the Right Answers, Right Away</h1>
                <p className="subtitle">Fast, Relevant, and Reliable Search Results</p>
                <div className="search-demo" style={{display: 'none'}}>
                  <img src={JSONData.searchBarImg} alt="Search Bar Demo" className="search-bar-img" />
                </div>
                <div className="cta-buttons">
                  <a
                    role="button"
                    className="cta-button primary"
                    onClick={() => { window.triggerInstall() }}
                    data-cy="cta"
                  >
                    Download Extension
                  </a>
                  <div className="hero-disclosure">
                    {disclosure}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="what-we-do">
            <div className="container">
              <h2>Our Mission</h2>
              <div className="mission-content">
                <p>Better Search brings clarity to your searches by delivering relevant results fast. Designed with efficiency in mind, Better Search understands your queries and provides results that matter, so you spend less time searching and more time finding what's important to you. Experience effortless search that works for you.</p>
              </div>
            </div>
          </section>

          <section className="benefits">
            <div className="container">
              <div className="benefits-grid">
                <div className="benefit-card">
                  <div className="icon">
                  </div>
                  <h3>Lightning-Fast Answers</h3>
                  <p>“Find what you’re looking for at the speed of thought.”</p>
                  <p>Our technology delivers instant answers. With Better Search, you get what you need quickly—no extra steps required.</p>
                </div>
                <div className="benefit-card">
                  <div className="icon">
                  </div>
                  <h3>Smart Results</h3>
                  <p>“Stay focused with results tailored to your searches.”</p>
                  <p>Say goodbye to distractions, irrelevant links, and shady sites. Better Search’s advanced search engine prioritizes relevance, giving you the answers you want without the noise, so you can keep your attention on what matters.</p>
                </div>
                <div className="benefit-card">
                  <div className="icon">
                  </div>
                  <h3>Responsible and Confidential</h3>
                  <p>“We respect your information and your choices”</p>
                  <p>Your searches remain yours, and your data is handled with care. We are committed to ensuring your information is treated responsibly and with respect.</p>
                </div>
              </div>
            </div>
          </section>

          <section className="download-section">
            <div className="container">
              <h2>Ready to improve your search experience?</h2>
              <div className="download-content">
                <a
                  href="#"
                  className="cta-button primary"
                  onClick={() => { window.triggerInstall() }}
                  data-cy="cta"
                >
                  Download Extension
                </a>
                <div className="download-disclosure">
                  {disclosure}
                </div>
              </div>
            </div>
          </section>
        </main>

        <RawFooter language='en' />
      </div>
    </HomepageLayout>
  )
}
